import { useQuery } from '@urql/vue';
import type { MaybeRef, Ref } from 'vue';
import type { RawEventListItemFragment } from '../gql/fragments/__generated/RawEventListItem';
import {
  EventSearchDocument,
  type EventSearchQuery,
  type EventSearchQueryVariables,
} from '../gql/queries/__generated/EventSearch';
import type { EventFilter } from '../gql/schema';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import type { ImxPlatformObject } from '../models/ImxPlatformObject';
import dayjs from 'dayjs';
export default async (
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  userFilter: MaybeRef<EventFilter> = ref<EventFilter>({}),
  baseFilter: MaybeRef<EventFilter> = ref<EventFilter>({}),
  query: MaybeRef<string> = ref<string>(''),
  pauseQuery: MaybeRef<boolean> = false,
  pageSize: Ref<number> = ref<number>(10)
) => {
  const { locale } = useI18n();
  const instanceConfig = useWhlInstanceConfig();
  const today = dayjs().format('YYYY-MM-DD');

  const filter = computed(() => ({
    and: [toValue(baseFilter), toValue(userFilter)],
  }));

  const fromDate = computed(
    () => toValue(userFilter).fromDate || toValue(baseFilter).fromDate || today
  );

  const { fetching, data, error, pause, resume } = await useQuery<
    EventSearchQuery,
    EventSearchQueryVariables
  >({
    query: EventSearchDocument,
    variables: {
      language: locale,
      filter: filter,
      query: query,
      appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      listImageFilter:
        instanceConfig.value.event?.list?.filter ??
        instanceConfig.value.defaults.list?.filter,
      listImageFallback:
        instanceConfig.value.event?.list?.fallback ??
        instanceConfig.value.defaults.list?.fallback,
      pageSize: pageSize,
      fromDate: fromDate,
    },
    pause: pauseQuery,
  });

  const totalRecords: Ref<number> = computed(() => {
    return data.value?.events?.pagination?.totalRecords ?? 0;
  });

  const events: Ref<RawEventListItemFragment[]> = computed(() => {
    return (data.value?.events?.nodes ?? []).map((event) => ({
      ...event,
      title: toValue(useImxPlatformObjectHtmlTitle(event as ImxPlatformObject)),
      description: toValue(
        useImxPlatformObjectHtmlDescription(event as ImxPlatformObject)
      ),
      //mapImxPlatformImage(event.keyvisual),
    }));
  });

  return { fetching, error, data, totalRecords, events, pause, resume };
};
